import CustomWebsiteDevelopment from '../images/ServicesStockImages/CustomWebsiteDevelopment.jpeg'
import BrandingDesign from '../images/ServicesStockImages/design.jpeg'
import MarketingAutomation from '../images/ServicesStockImages/marketingautomation.jpeg'
import DataReporting from '../images/ServicesStockImages/datareporting.jpeg'
import CRMIntegrations from '../images/ServicesStockImages/crm.jpeg'
import ContentMarketing from '../images/ServicesStockImages/content.jpeg'
import SocialMediaMarketing from '../images/ServicesStockImages/socialmedia.jpeg'
import SEM from '../images/ServicesStockImages/searchenginemarketing.jpeg'
import SalesOperations from '../images/ServicesStockImages/sales.jpeg'

import WebsiteDevelopmentSVG from '../images/NovaServicesSVGs/WebsiteDevelopment.svg'
import BrandingDesignSVG from '../images/NovaServicesSVGs/BrandingDesign.svg'
import MarketingAutomationSVG from '../images/NovaServicesSVGs/MarketingAutomation.svg'
import DataReportingSVG from '../images/NovaServicesSVGs/DataReporting.svg'
import CRMIntegrationsSVG from '../images/NovaServicesSVGs/CRMIntegration .svg'
import ContentMarketingSVG from '../images/NovaServicesSVGs/ContentMarketing.svg'
import SocialMediaMarketingSVG from '../images/NovaServicesSVGs/SocialMediaMarketing.svg'
import SEMSVG from '../images/NovaServicesSVGs/SEM.svg'
import SalesOperationsSVG from '../images/NovaServicesSVGs/SalesOperations.png'


const ServicesArray= [
    {
        stockImage: CustomWebsiteDevelopment,
        serviceName: "Website Development",
        description: "We design and build beautiful, user friendly, and mobile responsive sites that will convert your customer wherever they are in their homebuying journey.",
        svg: WebsiteDevelopmentSVG  
    },
    {
        stockImage: BrandingDesign,
        serviceName: "Branding & Design",
        description: "We explore your brand values and connect your audience to these through your reflective brand voice and visiual identify.",
        svg: BrandingDesignSVG  
    },
    {
        stockImage: MarketingAutomation,
        serviceName: "Marketing Automation",
        description: "We implement software for larger businesses that automates and manages marketing tasks to segment, nurture and convert leads into customers.",
        svg: MarketingAutomationSVG  
    },
    {
        stockImage: DataReporting,
        serviceName: "Data Reporting",
        description: "We create fresh and insightful dashboards that allow you and your team to make ongoing data driven decisions.",
        svg: DataReportingSVG  
    },
    {
        stockImage: CRMIntegrations,
        serviceName: "CRM & Integrations",
        description: "We implement or improve your CRM system and integrate with services like Finmo to automate and streamline your workflow.",
        svg: CRMIntegrationsSVG  
    },
    {
        stockImage: ContentMarketing,
        serviceName: "Content Marketing",
        description: "We create and distribute content to your segmented lists of leads, customers and real estate partners.",
        svg: ContentMarketingSVG  
    },
    {
        stockImage: SocialMediaMarketing,
        serviceName: "Social Media Marketing",
        description: "We provide insightful and social-friendly content to your audience and further establish trust, drive user engagement and boost sharing.",
        svg: SocialMediaMarketingSVG  
    },
    {
        stockImage: SEM,
        serviceName: "Search Engine Marketing",
        description: "We conduct SEO research and testing, predict and implement the best ways to get your website to the top of the results and bring in quality traffic.",
        svg: SEMSVG  
    },
    {
        stockImage: SalesOperations,
        serviceName: "Sales Operations",
        description: "We conduct an assessment to identify opportunities for improvement in your sales process and remain an integral part of your team on an ongoing basis.",
        svg: SalesOperationsSVG  
    }
  
    

]

export default ServicesArray 

